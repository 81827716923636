import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type IndexPageProps = {
    location: any;
};

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;

const NewsletterSignupPage = ({ location }: IndexPageProps) => {
    const TARGET_ID = 'hubspot-target';

    useEffect(() => {
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: '1994b7b2-505f-4f49-a797-212688d54b05',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <Layout location={location}>
            <div>
                <Helmet>
                    <title>SquareHook Newsletter Sign up</title>
                </Helmet>

                {/*sign up form*/}
                <div className="container mb-5">
                    <div className="row">
                        <div className={'col-md-6 center'}>
                            <p className="mainText">
                                Sign up to get the latest reports and insights in the industry, as well as news and
                                special promotions, right to your inbox!
                            </p>
                            <img className="img-fluid" src="../images/newsletter-sign-up.png"></img>
                        </div>
                        <div className={'col-md-6'}>
                            <h3 className={'center'}>Newsletter Sign Up Here!</h3>
                            <div id={TARGET_ID}></div>
                        </div>
                    </div>
                </div>
                {/*end sign up form*/}
            </div>
        </Layout>
    );
};
export default NewsletterSignupPage;
